import React from "react";
import { PiUsers } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import moment from "moment/moment";

const DeleteModal = ({ closeModal, handleDelete, getList }: any) => {
  return (
    <div className="h-[200px]">
      <div className="flex flex-col gap-y-2 w-[400px] bg-white p-4">
        <div className="text-center">
          Are you sure you want to delete this record?
        </div>
        <div className="flex gap-2 justify-center">
          <button
            className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
            onClick={() => closeModal()}
          >
            No
          </button>
          <button
            type="submit"
            onClick={() => {
              handleDelete();
              closeModal();
            }}
            className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
