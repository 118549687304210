import React, { useRef, useState } from "react";
import { AiOutlineAlignLeft } from "react-icons/ai";
import CustomImage from "../customImage";
import MobileLogo from "../../../assets/images/png/logo-small.png";
import InputSearch from "../input/InputSearch";
import { FaRegBell, FaRegUser } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { GoLock } from "react-icons/go";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Modal from "../modalWrapper/Modal";
import PasswordModal from "../../modal/PasswordModal";
import ProfileDetailModal from "../../modal/ProfileDetailModal";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../feature/auth";
import { Link, useNavigate } from "react-router-dom";
import useSearch from "../../../hooks/useSearch";
import useFilters from "../../../hooks/useFilters";
const DashboardHeader = () => {
  const wrapperRef = useRef(null);
  // const [showdropDown, setShowdropDown] = useState(false);
  const [showdropDown, setShowdropDown] = useOutsideClick(wrapperRef);
  const [changePassword, setChangePassword] = useOutsideClick(wrapperRef);
  const [changePersonalDetail, setChangePersonalDetail] =
    useOutsideClick(wrapperRef);
  // const [query, setQuery] = useState<string>("");
  const { islogin, user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { query, setQuery } = useSearch({});
  const { search, setSearch } = useFilters();

  const logoutUser = () => {
    localStorage.clear();
    localStorage.removeItem("persist:root");
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="relative">
      <div className="flex items-center px-[16px] lg:px-[64px] py-[12px] lg:py-[18px] lg:hidden">
        {/* <div className="w-[100px]">
          <AiOutlineAlignLeft className="w-[32px] h-[32px]" />
        </div> */}
        <div className="grow">
          <div
            className="w-max h-[44px] cursor-pointer"
            onClick={() => navigate("/")}
          >
            <CustomImage src={MobileLogo} alt="logo" />
          </div>
        </div>
        <div className="flex items-center gap-[16px]">
          {/* <FaRegBell className="w-[24px] h-[24px]" /> */}
          <FaRegUser
            className="w-[24px] h-[24px]"
            onClick={() => setShowdropDown((prev: any) => !prev)}
          />
        </div>
      </div>
      <div className="hidden lg:block relative">
        <div className="flex justify-between items-center  lg:px-[64px] lg:py-[18px]">
          <div className="flex gap-[24px]">
            <div
              className="w-max h-[44px] cursor-pointer"
              onClick={() => navigate("/")}
            >
              <CustomImage src={MobileLogo} alt="logo" />
            </div>
            {/* <div>
              <ul className="flex gap-[24px]">
                <Link to={"/about"}>
                  <li>About us</li>
                </Link>
                <Link to={"/contact"}>
                  <li>Contact us</li>
                </Link>
              </ul>
            </div> */}
          </div>
          <div className="flex items-center gap-[16px]">
            <div className="flex gap-[24px] items-center">
              {user?.role === "BUILDER" ? (
                <InputSearch
                  placeholder={"Search"}
                  css={undefined}
                  classDefName={undefined}
                  inputValue={search}
                  setInputValue={setSearch} // inputValue={query}
                />
              ) : (
                ""
              )}
              {/* <FaRegBell
                className="w-[24px] h-[24px]"
                style={{ cursor: "pointer" }}
              /> */}
            </div>
            <div>
              <FaRegUser
                className="w-[24px] h-[24px]"
                style={{ cursor: "pointer" }}
                onClick={() => setShowdropDown((prev: any) => !prev)}
              />
            </div>
          </div>
        </div>
      </div>
      {showdropDown ? (
        <div className="w-[266px] h-max absolute right-[15px] top-[60px] lg:right-[65px] lg:top-[75px] border border-gray-300 bg-white z-50">
          <div className="px-[16px] py-[12px] w-full  border-b border-gray-300 ">
            <div className="flex gap-[12px]">
              <div className="h-[60px] w-[60px] rounded-full">
                <img
                  src={`${user?.profileImage}`}
                  alt="profile"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div>
                <p className="text-[14px] font-semibold">{`${user?.firstName} ${user?.lastName}`}</p>

                <p className="text-[14px] font-normal">{user?.email}</p>
              </div>
            </div>
          </div>
          <div
            onMouseLeave={() => setShowdropDown(false)}
            onMouseEnter={() => setShowdropDown(true)}
          >
            <div className="px-[6px] py-[2px] h-[42px]">
              <div
                className="px-[10px] py-[9px] flex items-center gap-[12px] cursor-pointer"
                onClick={() => {
                  setChangePersonalDetail(true);
                  setShowdropDown(false);
                }}
              >
                <FiUser />
                <p className="text-[14px]">View Profile</p>
              </div>
            </div>
            <div className="px-[6px] py-[2px] h-[42px]">
              <div
                className="px-[10px] py-[9px] flex items-center gap-[12px] cursor-pointer"
                onClick={() => {
                  setChangePassword(true);
                  setShowdropDown(false);
                }}
              >
                <GoLock />
                <p className="text-[14px]">Change Password</p>
              </div>
            </div>
            <div className="px-[6px] py-[2px] h-[42px] border-t border-gray-300">
              <div
                className="px-[10px] py-[9px] flex items-center gap-[12px] cursor-pointer"
                onClick={() => {
                  // setChangePassword(true);
                  logoutUser();
                  setShowdropDown(false);
                }}
              >
                <BsBoxArrowRight style={{ width: 20, height: 20 }} />
                <p className="text-[14px]">Logout</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {changePassword && (
        <Modal
          closeModal={() => setChangePassword(false)}
          refProp={wrapperRef}
          variant={"default"}
        >
          <PasswordModal closeModal={() => setChangePassword(false)} />
        </Modal>
      )}
      {changePersonalDetail && (
        <Modal
          closeModal={() => setChangePersonalDetail(false)}
          refProp={wrapperRef}
          variant={"default"}
        >
          <ProfileDetailModal
            closeModal={() => setChangePersonalDetail(false)}
            userData={user}
          />
        </Modal>
      )}
    </div>
  );
};

export default DashboardHeader;
