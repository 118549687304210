import React from "react";
import { AiOutlineAlignLeft } from "react-icons/ai";
import CustomImage from "../customImage";
import MobileLogo from "../../../assets/images/png/mobile-logo.png";
import { Link } from "react-router-dom";

const AuthHeader = () => {
  return (
    <div>
      <div className="flex items-center px-[16px] lg:px-[64px] py-[12px] lg:py-[18px] lg:hidden">
        <div className="w-[126px]">
          <AiOutlineAlignLeft className="w-[32px] h-[32px]" />
        </div>
        <div className="grow">
          <div className="w-[118px]">
            <CustomImage src={MobileLogo} alt="logo" />
          </div>
        </div>
        <div></div>
      </div>
      <div className="flex items-center px-[16px] lg:px-[64px] py-[12px] lg:py-[18px] hidden lg:block">
        <div>
          <ul className="flex gap-[24px]">
            <Link to={"/about"} className="capitalize">
              About us
            </Link>
            <Link to={"/contact"} className="capitalize">
              Contact us
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
