import axiosInstance from "../api/axios";

export const login = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/auth/login`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const loginAdmin = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/admin/login`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const signUp = async (data: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.post(`/auth/signup`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.post(`/auth/password-reset`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const userResetPassword = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/auth/reset-password`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const userForgotPassword = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/auth/forgot-password`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
