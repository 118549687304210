import { Form, Formik } from "formik";
import React, { FormEvent, MouseEventHandler, useState } from "react";
import InputText from "../shared/input/InputText";
import InputSearch from "../shared/input/InputSearch";
import { IoCloseOutline } from "react-icons/io5";
import Dropzone from "react-dropzone";
import Thumb from "../shared/thumb";
import SearchableDropdown from "../shared/input/SearchableDropdown";
import CustomImage from "../shared/customImage";
import useAuth from "../../hooks/useAuth";
import {
  createAdminSchema,
  editAdminSchema,
  editUserSchema,
} from "../../utils/validationSchema/auth";
import useUsers from "../../hooks/useUsers";
import toast from "react-hot-toast";

const AdminModal = ({
  closeModal,
  requestType,
  getAllData,
  userData,
  setSelectedStateId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const { createUser } = useAuth();
  const { updateUserDetail } = useUsers({});

  const initialValues = {
    firstName: requestType === "edit" ? userData?.firstName : "",
    lastName: requestType === "edit" ? userData?.lastName : "",
    role: requestType === "edit" ? userData?.role : "",
    email: requestType === "edit" ? userData?.email : "",
    password: requestType === "edit" ? "" : "",
    phoneNumber: requestType === "edit" ? userData?.phoneNumber : "",
  };

  const onSubmit = async (data: any) => {
    try {
      if (requestType === "edit") {
        setLoading(true);
        const payload = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          role: data?.role,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
        };
        const res = await updateUserDetail(userData._id, {
          ...payload,
        });
        if (res.code === "00") {
          toast.success("Admin updated");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating Admin");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const payload = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          role: data?.role,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
        };
        const res = await createUser({
          ...payload,
        });
        if (res.code === "00") {
          toast.success("Admin created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          closeModal();
          toast.error(res.message || "Error creating Admin");
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
      if (getAllData) {
        setTimeout(() => {
          getAllData();
        }, 1000);
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        requestType === "edit" ? editAdminSchema : createAdminSchema
      }
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        console.log({ errors });
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-2 min-w-[258px] md:min-w-[450px] lg:w-[258px]  bg-white p-4 rounded-[12px] ">
              <div className="flex justify-between w-full">
                <div className="pb-[20px]">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit New Admin"
                      : "Create New Admin"}
                  </h1>
                  <p className="text-sm">
                    {requestType === "edit"
                      ? "Edit the details of the new admin"
                      : "Provide the details of the new admin"}
                  </p>
                </div>
                <div className="">
                  <IoCloseOutline
                    style={{ width: 26, height: 26, cursor: "pointer" }}
                    onClick={() => closeModal()}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[32px]">
                <div className="h-[44px] w-full">
                  <SearchableDropdown
                    options={[
                      { label: "Admin", value: "ADMIN" },
                      { label: "Super Admin", value: "SUPER_ADMIN" },
                    ]}
                    placeholder={"select a role"}
                    label={"User role"}
                    defaultInputValue={values.role}
                    wrapperClassName="w-full"
                    handleChange={(e: any) => {
                      setFieldValue("role", e.value);
                    }}
                  />
                </div>
                <div className="pt-[12px]">
                  <div className="h-[1px] border-t border-gray-300"></div>
                </div>
                <div className="flex flex-col gap-[16px]">
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"First Name"}
                      name="firstName"
                      error={errors.firstName as string}
                      value={values.firstName}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter full name"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"last Name"}
                      name="lastName"
                      error={errors.lastName as string}
                      value={values.lastName}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter full name"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"Email Address"}
                      name="email"
                      error={errors.email as string}
                      value={values.email}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter email"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                  {/* {requestType !== "edit" ? (
                    <div className="flex justify-between gap-2">
                      <InputText
                        label={"Password"}
                        name="password"
                        error={errors.password as string}
                        value={values.password}
                        fieldRequired={true}
                        // className={"sm:w-[150px]"}
                        placeholder={"Enter password"}
                        handleChange={handleChange}
                        unit={""}
                        type={"password"}
                        inputClassName={""}
                        textStyle={""}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                  <div className="flex gap-2">
                    <InputText
                      label={"Phone Number"}
                      name="phoneNumber"
                      error={errors.phoneNumber as string}
                      value={values.phoneNumber}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter phone number"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-2 justify-center pt-[32px]">
                <button
                  className="mx-auto px-2 w-full h-[40px] rounded-[8px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                  type="button"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-[#A74225] rounded-[8px] text-white flex justify-center items-center h-[40px] w-full gap-2"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-[#A74225] rounded-[8px] text-white flex justify-center items-center h-[40px] w-full gap-2"
                  >
                    {loading ? "Creating..." : "Proceed"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminModal;
