import React, { useState } from "react";
import { Form, Formik } from "formik";

import Header from "../../components/shared/header/AuthHeader";
import Footer from "../../components/shared/footer";
import CustomImage from "../../components/shared/customImage";
import BuilderImage from "../../assets/images/png/builder-image.png";
import Logo from "../../assets/images/png/mobile-logo.png";
import InputText from "../../components/shared/input/InputText";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/shared/layouts/AuthLayout";
import useUsers from "../../hooks/useUsers";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { userForgotPasswordSchema } from "../../utils/validationSchema/auth";

const ForgotPassword = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { forgotPassword } = useAuth();

  const initialValues = {
    // fullName: "",
    email: "",
  };
  const signIn = async (data: any) => {
    console.log({ data });
    try {
      setLoading(true);
      const res = await forgotPassword(data);
      if (res.code === "00") {
        setLoading(false);
        toast.success(res?.message);
      }
      if (res.code !== "00") {
        setLoading(false);
        toast.error(res.message || "Error");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <AuthLayout>
      <div className="grow flex flex-col lg:flex-row lg:w-full gap-[32px] lg:gap-[88px] px-[16px] lg:px-[64px]">
        <div className="flex flex-col justify-center gap-[24px] lg:w-[411px]">
          <div className="w-[188px]">
            <CustomImage src={Logo} alt="builder" />
          </div>
          <div>
            <h1 className="font-bold text-[24px]">Forgot password</h1>
            <p>Provide the detail(s) below to regain access.</p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={userForgotPasswordSchema}
              onSubmit={signIn}
            >
              {({ handleChange, errors, values, handleSubmit }) => {
                console.log({ values });
                console.log({ errors });
                return (
                  <Form>
                    {/* <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your full name"}
                        label={"Full name"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.fullName}
                        name={"fullName"}
                        unit={""}
                        type={"text"}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.fullName}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        viewPassword={false}
                        inputClassName={""}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div> */}

                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your email"}
                        label={"Email"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.email}
                        name={"email"}
                        unit={""}
                        type={"text"}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.email}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        inputClassName={""} //   viewPassword={false}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>
                    <div className="mt-[24px] w-full">
                      <button
                        className="bg-[#A74225] rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                        type="submit"
                        // onClick={() => handleSubmit()}
                      >
                        {loading ? "loading..." : "Submit"}
                      </button>
                    </div>
                    <div
                      className="mt-4 mb-[32px] flex justify-center gap-1 w-full"
                      //   onClick={() => navigate("/forgotpassword")}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="mb-0 text-sm">
                        Remember your details?{" "}
                        <Link to={"/"} className="text-[#A74225]">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div>
          <div className="lg:h-[566.38px]">
            <CustomImage src={BuilderImage} alt="builder" />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
