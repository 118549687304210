import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputText from "../shared/input/InputText";
import InputSearch from "../shared/input/InputSearch";
import { IoCloseOutline } from "react-icons/io5";
import Dropzone from "react-dropzone";
import Thumb from "../shared/thumb";
import SearchableDropdown2 from "../shared/input/SearchableDropdown2";
import useUsers from "../../hooks/useUsers";
import { useDebouncedCallback } from "use-debounce";
import DragAndDropInput from "../shared/input/DragAndDropInput";
import CustomImage from "../shared/customImage";
import { RiDeleteBin6Line } from "react-icons/ri";
import useFolders from "../../hooks/useFolders";
import { uploadImageSchema } from "../../utils/validationSchema/uploadImage";
import useUploads from "../../hooks/useUploads";
import toast from "react-hot-toast";

const UploadImageModal = ({
  closeModal,
  requestType,
  data,
  getData,
  loadOptionsOne,
  loadOptionsTwo,
  userData,
  setSelectedStateId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<any>([]);
  const { users: builders } = useUsers({ role: "BUILDER" });
  const { users: owners } = useUsers({ role: "OWNER" });
  const { createFolders, updateFolderFilesById } = useFolders({});
  const { uploadFiles } = useUploads();
  const removeFile = (values: any, name: string) => {
    setPreviewFiles((files: any) =>
      files.filter((file: any) => file.name !== name)
    );
    values.files.filter((file: any) => file.name !== name);
  };

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () =>
      previewFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [previewFiles]);

  const initialValues = {
    files: [],
    folder: requestType === "edit" ? userData?.name : "",
  };
  console.log({ userData });

  const onSubmit = async (data: any) => {
    try {
      // if (requestType === "edit") {
      //   setLoading(true);
      //   const res = await updateExistingAggregator({
      //     ...data,
      //     id: useerData.id,
      //     programId: program,
      //   });
      //   if (res.code === "00") {
      //     toast.success("Aggregator updated");
      //     closeModal();
      //   }
      //   if (res.code !== "00") {
      //     setLoading(false);
      //     toast.error(res.message || "Error updating waybill");
      //   }
      // }
      if (requestType !== "edit") {
        setLoading(true);
        const formData: any = new FormData();
        data.files.map((file: any) => {
          formData.append("files", file);
        });
        const files: any = await uploadFiles(formData);

        const newData = files?.data?.map((filedata: any, index: number) => {
          return {
            size: data.files[index].size,
            mediaUrl: filedata?.location,
            key: filedata?.key,
            name: previewFiles[index].name,
            type: previewFiles[index].type,
            uploadedAt: Date.now(),
          };
        });
        if (files.data.length !== 0) {
          const res: any = await updateFolderFilesById(userData.id, {
            files: newData,
          });
          if (res.code === "00") {
            toast.success("Upload Successful");
            closeModal();
          }
          if (res.code !== "00") {
            setLoading(false);
            toast.error(res.message || "Error uploading media");
          }
        }
      }
    } catch (error: any) {
      console.log({ error });
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
      if (getData) {
        setTimeout(() => {
          getData();
        }, 1000);
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={uploadImageSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        console.log({ errors });
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-2 min-w-[258px] md:min-w-[450px] lg:w-[576px]  bg-white p-4 rounded-[12px] ">
              <div className="flex justify-between w-full">
                <div className="pb-[20px]">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit Aggregator"
                      : "Upload Media"}
                  </h1>
                  <p className="text-sm">Upload media content to this folder</p>
                </div>
                <div className="">
                  <IoCloseOutline
                    style={{ width: 26, height: 26, cursor: "pointer" }}
                    onClick={() => closeModal()}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[32px]">
                {/* <div className="flex justify-between gap-2">
                  <SearchableDropdown2
                    label={"Search Folder"}
                    options={data.map((data: any) => {
                      return {
                        label: data.folderName,
                        value: data._id,
                      };
                    })}
                    className="w-full"
                    handleChange={(e: any) => {
                      setFieldValue("folder", e.value);
                    }}
                  />
                </div> */}
                <div className="flex flex-col gap-[16px]">
                  <div className="">
                    <h1 className="capitalize font-bold">Folder Details</h1>
                    <p className="text-sm">
                      Provide the details for the folder
                    </p>
                  </div>
                  <div className="h-[74px] border border-gray-300 flex items-center justify-center rounded-[12px]">
                    <DragAndDropInput
                      setFieldValue={setFieldValue}
                      values={values}
                      setPreviewFiles={setPreviewFiles}
                    />
                  </div>
                  <div className="flex flex-wrap gap-[12px]">
                    {previewFiles.map((data: any, index: number) => {
                      return (
                        <div
                          className="flex gap-[16px] items-center py-[8px] px-[16px] rounded-[8px] border border-gray-300"
                          key={index}
                        >
                          <div className="flex gap-[8px]">
                            <div className="w-[31px] h-[31px]">
                              <CustomImage
                                src={data?.preview}
                                alt="preview"
                                onLoad={() => {
                                  URL.revokeObjectURL(data?.preview);
                                }}
                              />
                            </div>
                            <div>
                              <p className="text-[12px] font-medium">
                                {data.name}
                              </p>
                              <p className="text-[12px]">
                                200 KB – 100% uploaded
                              </p>
                            </div>
                          </div>
                          <RiDeleteBin6Line
                            style={{ cursor: "pointer" }}
                            onClick={() => removeFile(values, data?.name)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="flex gap-2 justify-center pt-[32px]">
                <button
                  className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272 rounded-[8px]"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
                  >
                    {loading ? "Creating..." : "Proceed"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UploadImageModal;
