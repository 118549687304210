import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputText from "../shared/input/InputText";
import InputSearch from "../shared/input/InputSearch";
import { IoCloseOutline } from "react-icons/io5";
import Dropzone from "react-dropzone";
import Thumb from "../shared/thumb";
import SearchableDropdown from "../shared/input/SearchableDropdown";
import CustomImage from "../shared/customImage";
import useAuth from "../../hooks/useAuth";
import {
  createUserSchema,
  editAdminSchema,
  editUserSchema,
} from "../../utils/validationSchema/auth";
import Avatar from "../../assets/images/png/Avatar.png";
import useUsers from "../../hooks/useUsers";
import DragAndDropInput from "../shared/input/DragAndDropInput";
import useUploads from "../../hooks/useUploads";
import toast from "react-hot-toast";

const UserModal = ({
  closeModal,
  requestType,
  getAllData,
  userData,
  builderoptions,
  setSelectedStateId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<any>([]);
  const { createUser } = useAuth();
  const { updateUserDetail } = useUsers({});
  const { uploadFiles } = useUploads();

  const initialValues = {
    firstName: requestType === "edit" ? userData?.firstName : "",
    lastName: requestType === "edit" ? userData?.lastName : "",
    role: requestType === "edit" ? userData?.role : "",
    files: [],
    email: requestType === "edit" ? userData?.email : "",
    address: requestType === "edit" ? userData?.address : "",
    phoneNumber: requestType === "edit" ? userData?.phoneNumber : "",
    builder: requestType === "edit" ? userData?.phoneNumber : "",
  };
  console.log({ builderoptions });

  const onSubmit = async (data: any) => {
    try {
      if (requestType === "edit") {
        setLoading(true);
        let files: any;
        if (data?.files?.length > 0) {
          const formData: any = new FormData();
          data.files.map((file: any) => {
            formData.append("files", file);
          });
          files = await uploadFiles(formData);
        }
        const payload = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          role: data?.role,
          email: data?.email,
          address: data?.address,
          phoneNumber: data?.phoneNumber,
        };
        const res = await updateUserDetail(userData._id, {
          ...payload,
          profileImage: files?.data[0],
        });
        if (res.code === "00") {
          toast.success("User updated");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating User");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);

        let files: any;
        if (data?.files?.length > 0) {
          const formData: any = new FormData();
          data.files.map((file: any) => {
            formData.append("files", file);
          });
          files = await uploadFiles(formData);
        }
        console.log({ files: files?.data });
        delete data.files;
        if (data?.role === "BUILDER") {
          const payload = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            role: data?.role,
            email: data?.email,
            address: data?.address,
            phoneNumber: data?.phoneNumber,
          };
          const res = await createUser({
            ...payload,
            profileImage: files?.data[0]?.location,
          });
          if (res.code === "00") {
            toast.success("User created successfully");
            closeModal();
          }
          if (res.code !== "00") {
            setLoading(false);
            closeModal();
            toast.error(res.message || "Error creating User");
          }
        }
        if (data?.role === "OWNER") {
          const res = await createUser({
            ...data,
            profileImage: files?.data[0]?.location,
          });
          if (res.code === "00") {
            toast.success("User created successfully");
            closeModal();
          }
          if (res.code !== "00") {
            setLoading(false);
            closeModal();
            toast.error(res.message || "Error creating User");
          }
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
      if (getAllData) {
        setTimeout(() => {
          getAllData();
        }, 1000);
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        requestType === "edit" ? editUserSchema : createUserSchema
      }
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ height: "500px" }}>
            <div className="flex flex-col gap-y-2 min-w-[258px] md:min-w-[450px] lg:w-[576px]  bg-white p-4 rounded-[12px] ">
              <div className="flex justify-between w-full">
                <div className="pb-[20px]">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit" ? "Edit User" : "Create New User"}
                  </h1>
                  <p className="text-sm">Provide the details of the new user</p>
                </div>
                <div className="">
                  <IoCloseOutline
                    style={{ width: 26, height: 26, cursor: "pointer" }}
                    onClick={() => closeModal()}
                  />
                </div>
              </div>
              <div className="w-full pb-[12px]">
                <SearchableDropdown
                  options={[
                    { label: "Home Owner", value: "OWNER" },
                    { label: "Builder", value: "BUILDER" },
                  ]}
                  placeholder={"Select a role"}
                  wrapperClassName="w-full"
                  label={"User role"}
                  defaultInputValue={values.role}
                  handleChange={(e) => setFieldValue("role", e.value)}
                />
              </div>
              <div className="border-t border-gray-300 pt-[12px] pb-[12px]">
                <div className="h-[1px]"></div>
              </div>
              <div className="flex flex-col gap-[32px]">
                <div className="flex items-center gap-[16px]">
                  <div className="h-[74px] w-[74px] rounded-full">
                    <CustomImage
                      src={
                        previewFiles.length !== 0 || userData?.profileImage
                          ? previewFiles[0]?.preview || userData?.profileImage
                          : Avatar
                      }
                      alt="avatar"
                    />
                  </div>
                  <div className="flex-grow h-[74px] border border-gray-300 py-[16px] px-[24px] flex items-center justify-center rounded-[12px]">
                    <DragAndDropInput
                      setFieldValue={setFieldValue}
                      values={values}
                      setPreviewFiles={setPreviewFiles}
                      maxFile={1}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[16px]">
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"First Name"}
                      name="firstName"
                      error={errors.firstName as string}
                      value={values.firstName}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter First Name"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"Last Name"}
                      name="lastName"
                      error={errors.lastName as string}
                      value={values.lastName}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter Last Name"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"Property Address"}
                      name="address"
                      error={errors.address as string}
                      value={values.address}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter Property Address"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <InputText
                      label={"Email Address"}
                      name="email"
                      error={errors.email as string}
                      value={values.email}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter Email Address"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>

                  {values.role !== "BUILDER" ? (
                    <div className="w-full pb-[12px]">
                      <SearchableDropdown
                        options={builderoptions}
                        placeholder={"Select a builder"}
                        wrapperClassName="w-full"
                        label={"Builder"}
                        defaultInputValue={values.role}
                        handleChange={(e) => setFieldValue("builder", e.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {requestType !== "edit" ? (
                    <div className="flex justify-between gap-2">
                      <InputText
                        label={"Password"}
                        name="password"
                        error={errors.password as string}
                        value={values.password}
                        fieldRequired={true}
                        // className={"sm:w-[150px]"}
                        placeholder={"Enter password"}
                        handleChange={handleChange}
                        unit={""}
                        type={"password"}
                        inputClassName={""}
                        textStyle={""}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                  <div className="flex gap-2">
                    <InputText
                      label={"Phone Number"}
                      name="phoneNumber"
                      error={errors?.phoneNumber as string}
                      value={values.phoneNumber}
                      fieldRequired={true}
                      // className={"sm:w-[150px]"}
                      placeholder={"Enter Phone Number"}
                      handleChange={handleChange}
                      unit={""}
                      type={"text"}
                      inputClassName={""}
                      textStyle={""}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-2 justify-center">
                <button
                  className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272 rounded-[8px]"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>

                {requestType === "edit" ? (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
                  >
                    {loading ? "Updating..." : "Save changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
                  >
                    {loading ? "Creating..." : "Proceed"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserModal;
