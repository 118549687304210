import React, { useState } from "react";
import CustomButton from "../button";
import { Link, useNavigate } from "react-router-dom";

const MainHeader = () => {
  const navigate = useNavigate();
  const [navIndex, setNavIndex] = useState<number>(0);
  const nav = [
    { id: 1, label: "about us" },
    { id: 2, label: "contact us" },
  ];

  return (
    <div className="px-[16px] lg:px-[64px] py-[18px]">
      <div className="flex justify-between items-center  lg:px-[64px] lg:py-[18px]">
        <div className="flex gap-[24px]">
          <div>
            <ul className="flex gap-[24px]">
              {nav.map((data, index) => {
                return (
                  <Link
                    to={"/about"}
                    className={`${
                      index === navIndex ? "text-[#7D311C]" : ""
                    } capitalize`}
                    onClick={() => setNavIndex(index)}
                  >
                    {data?.label}
                  </Link>
                );
              })}
              {/* <Link to={"/contact"}>Contact us</Link> */}
            </ul>
          </div>
        </div>
        <div className="flex items-center gap-[16px]">
          <CustomButton
            className="w-[95px] bg-[#A74225] h-[44px] text-white"
            label={"Log In"}
            handleClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
