import React from "react";

interface CustomImageProps {
  src: string;
  alt: string;
  onLoad?: () => void;
  height?: string;
  scale?: any;
}
const CustomImage = ({
  src,
  alt,
  onLoad,
  scale,
  height = "100%",
  ...props
}: CustomImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{
        width: "100%",
        height: height,
        objectFit: "contain",
        transform: `scale(${scale})`,
        maxWidth: "100%",
        minWidth: "100%",
        // minHeight: "100%",
      }}
      onLoad={() => {
        if (onLoad) {
          onLoad();
        }
      }}
      {...props}
    />
  );
};

export default CustomImage;
