import React from "react";
import CustomImage from "../shared/customImage";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineChevronRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { MdRefresh } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { CiCircleInfo } from "react-icons/ci";
import { IoMdShareAlt } from "react-icons/io";
import { FiChevronDown } from "react-icons/fi";
import { TfiDownload } from "react-icons/tfi";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";
import CustomVideo from "../shared/customVideo";
import { FaHeart } from "react-icons/fa";
import { formatBytes } from "../../utils";

const ViewMedia = ({
  closeModal,
  handleDownload,
  data,
  scale,
  favourite,
  handleZoomIn,
  handleZoomOut,
  handleFavourite,
  handleUnFavourite,
  next,
  prev,
  imageIndex,
  showInfo,
  handleShowInfo,
}: {
  closeModal(): void;
  handleDownload?(url: string, name: string): void;
  data: any;
  scale?: number;
  favourite?: any;
  imageIndex?: number;
  handleZoomIn?(): void;
  handleZoomOut?(): void;
  handleFavourite?: () => void;
  handleUnFavourite?: () => void;
  next?(): void;
  prev?(): void;
  handleShowInfo?(): void;
  showInfo?: boolean;
}) => {
  // const handleDownload = (url: string, fileName: string) => {
  //   fetch(url, { mode: "no-cors", cache: "no-cache" })
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       console.log({ url });
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = fileName || "downloaded-file";
  //       document.body.appendChild(link);

  //       link.click();

  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the file:", error);
  //     });
  // };
  const checkString = (str: any) =>
    ["video/mp4", "video/gif"].some((word) => str.includes(word));
  return (
    <div className="w-[370px] lg:min-w-[700px] lg:w-max">
      <div className="flex justify-between pb-[10px] w-full px-[16px] lg:px-0">
        <div>
          <p className="text-white">{data?.name}</p>
        </div>
        <div className="">
          <IoCloseOutline
            style={{ width: 26, height: 26, cursor: "pointer", color: "white" }}
            onClick={() => closeModal()}
          />
        </div>
      </div>
      {checkString(data[imageIndex ? imageIndex : 0]?.type) ? (
        <div className="w-full h-[500px] lg:w-full lg:h-[591px] px-[16px] lg:px-0">
          <CustomVideo
            src={data[imageIndex ? imageIndex : 0]?.mediaUrl}
            alt={data[imageIndex ? imageIndex : 0]?.name}
          />
        </div>
      ) : (
        <div className="w-full h-[500px] lg:w-full lg:h-[591px] px-[16px] lg:px-0">
          <CustomImage
            src={data[imageIndex ? imageIndex : 0]?.mediaUrl}
            alt={data[imageIndex ? imageIndex : 0]?.name}
            scale={scale}
          />
        </div>
      )}
      <div className="flex flex-col gap-[20px] lg:gap-0 lg:flex-row lg:justify-between pt-[10px] px-[16px] lg:px-0">
        <div className="hidden lg:block p-[12px] bg-white h-[44px]">
          <MdOutlineChevronLeft
            onClick={() => {
              if (prev) {
                prev();
              }
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="lg:hidden p-[12px] flex justify-between gap-[8px] bg-transparent border border-[#98A2B3] h-[44px]">
          <AiOutlineMinus
            style={{ color: "white" }}
            onClick={() => {
              if (handleZoomOut) {
                handleZoomOut();
              }
            }}
          />
          <GoPlus
            style={{ color: "white" }}
            onClick={() => {
              if (handleZoomIn) {
                handleZoomIn();
              }
            }}
          />
        </div>
        <div className="flex gap-[16px]">
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            <MdRefresh style={{ color: "white" }} />
          </div>
          <div className="w-[148px] hidden lg:block">
            <div className="w-full flex justify-between items-center  p-[16px] gap-[8px] lg:p-[12px] border border-[#98A2B3] h-[44px]">
              <AiOutlineMinus
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  if (handleZoomOut) {
                    handleZoomOut();
                  }
                }}
              />
              <GoPlus
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  if (handleZoomIn) {
                    handleZoomIn();
                  }
                }}
              />
            </div>
          </div>
          <div
            className="flex items-center p-[16px] w-[72px] gap-[8px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px] cursor-pointer"
            onClick={() => {
              if (handleDownload) {
                handleDownload(data?.mediaUrl, data?.name);
              }
            }}
          >
            <TfiDownload style={{ color: "white" }} />
            <FiChevronDown style={{ color: "white" }} />
          </div>
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            {favourite ? (
              <FaHeart
                className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px] cursor-pointer`}
                style={{ color: "red" }}
                onClick={() => {
                  if (handleUnFavourite) {
                    handleUnFavourite();
                  }
                }}
              />
            ) : (
              <AiOutlineHeart
                className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px] cursor-pointer`}
                style={{ backgroundColor: `${favourite ? "red" : ""}` }}
                onClick={() => {
                  if (handleFavourite) {
                    handleFavourite();
                  }
                }}
              />
            )}
          </div>
          <div
            className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px] relative"
            onClick={() => {
              if (handleShowInfo) {
                handleShowInfo();
              }
            }}
          >
            <CiCircleInfo style={{ color: "white" }} />
            {showInfo ? (
              <div className="absolute top-[-72px] left-[10px] bg-white w-max h-max p-[10px] after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:bg-red after:border-t-[20px] after:border-t-[#ffffff] after:border-x-[15px] after:border-x-transparent after:h-0 after:w-0 ">
                <p>Name: {data[imageIndex ? imageIndex : 0]?.name}</p>
                <p>
                  Size: {formatBytes(data[imageIndex ? imageIndex : 0].size)}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            <IoMdShareAlt style={{ color: "white" }} />
          </div>
        </div>
        <div className="hidden lg:block p-[12px] bg-white h-[44px]">
          <MdOutlineChevronRight
            onClick={() => {
              if (next) {
                next();
              }
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="flex justify-center gap-[8px] lg:hidden">
          <div className="p-[12px] bg-white">
            <MdOutlineChevronLeft
              onClick={() => {
                if (prev) {
                  prev();
                }
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="p-[12px] bg-white">
            <MdOutlineChevronRight
              onClick={() => {
                if (next) {
                  next();
                }
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMedia;
