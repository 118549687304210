import React from "react";

interface props {
  placeholder: string;
  label: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {} | void;
  value: string;
  name: string;
  unit: string;
  type: string;
  className: string;
  error: string | undefined;
  fieldRequired?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  textStyle: string;
  viewPassword?: boolean;
  rightIcon?: boolean;
}
const InputTextarea = ({
  placeholder,
  label,
  handleChange,
  value,
  name,
  unit = "",
  type = "text",
  className,
  error,
  fieldRequired = false,
  readOnly = false,
  disabled = false,
  textStyle = "",
  viewPassword,
  rightIcon,
  ...prop
}: props) => {
  return (
    <div className="flex flex-col w-full">
      <label>{label}</label>
      <textarea
        name={name}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputTextarea;
