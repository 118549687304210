import React, { useRef, useState } from "react";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import CustomImage from "../../../components/shared/customImage";
import Home from "../../../assets/images/png/home.png";
import { MdFilterList } from "react-icons/md";
import DateInput from "../../../components/shared/DateInput";
import FolderIcon from "../../../assets/images/png/foldercloud-Bulk.png";
import { CiCirclePlus } from "react-icons/ci";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import { BsFolder } from "react-icons/bs";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Modal from "../../../components/shared/modalWrapper/Modal";
import CreateFolderModal from "../../../components/modal/CreateFolderModal";
import useFolders from "../../../hooks/useFolders";
import { useSelector } from "react-redux";
import useUsers from "../../../hooks/useUsers";
import { DateFilterFunc, formatBytes } from "../../../utils";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import UserModal from "../../../components/modal/UserModal";
import UploadImageModal from "../../../components/modal/UploadImageModal";
import useAdmins from "../../../hooks/useAdmin";
import { FaDiamond } from "react-icons/fa6";

const AdminDashboard = () => {
  const wrapperRef = useRef(null);
  const [createFolder, setCreateFolder] = useOutsideClick(wrapperRef);
  const [createUser, setCreateUser] = useOutsideClick(wrapperRef);
  const [uploadImage, setUploadImage] = useOutsideClick(wrapperRef);
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formatStartDate, setFormatStartDate] = useState<string | null>();
  const [formatEndDate, setFormatEndDate] = useState<string | null>();
  const [page, setPage] = useState<number>(1);
  const [role, setRole] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  const { folders, loading, getFolders } = useFolders({
    page,
    limit,
    startDate: formatStartDate,
    endDate: formatEndDate,
  });
  const { dashboardReport } = useAdmins({});
  const { users: builders } = useUsers({ role: "BUILDER" });
  const { users: owners } = useUsers({
    role: "OWNER",
    startDate: formatStartDate,
    endDate: formatEndDate,
  });
  console.log({ owners });

  const onChange = (dates: any) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    start
      ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
      : setFormatStartDate(null);

    end
      ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
      : setFormatEndDate(null);
  };

  return (
    <div className="pt-[32px] pr-[32px] pl-[24px] pb-[8px]">
      <div className="flex flex-col gap-[48px] pb-[32px] ">
        <div>
          <h1 className="text-[30px] font-semibold">
            Welcome {user?.fullName}
          </h1>
          <p className="font-normal text-[16px]">
            Check out your latest project updates.
          </p>
        </div>
        <div className="flex gap-[24px]">
          <div className="flex flex-col bg-[#FAEEEA] border border-gray-300 p-[20px] w-[348px] rounded-[12px]">
            <h1 className="text-[14px] font-medium">Active Projects</h1>
            <p className="text-[30px] font-semibold">
              {dashboardReport?.data?.projectCount || 0}
            </p>
          </div>
          <div className="flex flex-col bg-[#F9FAFB] border border-gray-300 p-[20px] w-[348px] rounded-[12px]">
            <h1 className="text-[14px] font-medium">User Count</h1>
            <p className="text-[30px] font-semibold">
              {dashboardReport?.data?.userCount || 0}
            </p>
          </div>
          <div className="flex flex-col bg-[#F5FAFF] border border-gray-300 p-[20px] w-[348px] rounded-[12px]">
            <h1 className="text-[14px] font-medium">Total Media</h1>
            <p className="text-[30px] font-semibold">
              {dashboardReport?.data?.totalMedia || 0}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-semibold text-[16px]">Quick Actions</p>
          <div className="flex gap-[24px]">
            {/* <div
              className="flex gap-[12px] border border-gray-300 p-[20px] w-[348px] rounded-[12px] cursor-pointer"
              onClick={() => setUploadImage(true)}
            >
              <div className="flex items-center justify-center border border-gray-300 w-[48px]">
                <AiOutlineVerticalAlignTop className="w-[24px] h-[24px]" />
              </div>
              <div>
                <h1 className="text-[16px] font-semibold">Upload Image</h1>
                <p className="text-[14px] font-normal">
                  Upload media to a project
                </p>
              </div>
            </div> */}
            <div
              className="flex gap-[12px] border border-gray-300 p-[20px] w-[348px] rounded-[12px] cursor-pointer"
              onClick={() => setCreateFolder(true)}
            >
              <div className="flex items-center justify-center border border-gray-300 w-[48px]">
                <BsFolder className="w-[24px] h-[24px]" />
              </div>
              <div>
                <h1 className="text-[16px] font-semibold">
                  Create a new folder
                </h1>
                <p className="text-[14px] font-normal">
                  Create a new project folder
                </p>
              </div>
            </div>
            <div
              className="flex gap-[12px] border border-gray-300 p-[20px] w-[348px] rounded-[12px] cursor-pointer"
              onClick={() => setCreateUser(true)}
            >
              <div className="flex items-center justify-center border border-gray-300 w-[48px]">
                <BsFolder className="w-[24px] h-[24px]" />
              </div>
              <div>
                <h1 className="text-[16px] font-semibold">Create a new user</h1>
                <p className="text-[14px] font-normal">
                  Upload media to a project
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[24px]">
        <h1 className="font-semibold">All Projects</h1>
        <div className="flex flex-col gap-[16px] lg:gap-0 lg:flex-row lg:justify-between ">
          <div className="flex h-[40px]">
            <div className="w-[173px]  lg:w-[189px]">
              <DateInput
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
              />
            </div>
            <div className="flex rounded-md w-max">
              {[
                { name: "30 days", year: "THIRTY_DAYS" },
                { name: "7 days", year: "SEVEN_DAYS" },
                { name: "Today", year: "TODAY" },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 py-[10px] px-[8px] text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => {
                      setFormatStartDate(DateFilterFunc(data?.year));
                      setFormatEndDate(moment().format("YYYY-MM-DD"));
                    }}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="flex gap-[12px] relative">
            {/* <button
              className="flex w-[103px] gap-[8px] h-[40px] items-center border border-gray-300 py-[10px] px-[16px] capitalize rounded-[8px]"
              onClick={() => setShowFilter(true)}
              onMouseLeave={() => setShowFilter(false)}
            >
              <MdFilterList />
              Filters
            </button> */}
            <button
              className="flex w-[181px] text-[14px] text-white gap-[8px] h-[40px] bg-[#A74225] items-center border border-gray-300 py-[10px] px-[16px] capitalize rounded-[8px]"
              onClick={() => setCreateFolder(true)}
            >
              <CiCirclePlus />
              Create new folder
            </button>
            {/* {showFilter ? (
              <div
                className="p-[14px] flex flex-col gap-[8px] border border-gray-300 absolute top-[42px] right-[188px] w-[297px] height-max bg-white rounded-[8px] z-50"
                ref={wrapperRef}
              >
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setShowFilter(false)}
                >
                  <h2 className="text-[14px] font-medium">All Folders</h2>
                  <MdArrowForwardIos className="w-[24px]" />
                </div>
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setShowFilter(false)}
                >
                  <h2 className="text-[14px] font-medium">Favourites</h2>
                  <MdArrowForwardIos className="w-[24px]" />
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-[20px]  lg:flex-row lg:flex-wrap  pb-[24px] lg:pb-[72px] w-full mr-0">
          {owners.map((data: any, index: number) => {
            console.log({ data });
            return (
              <div
                className="flex flex-col w-[180.96px]  h-[78.25px] lg:w-[313px] lg:h-[160px]  rounded-[12px] border border-gray-300 cursor-pointer"
                onClick={() => navigate(`/admin/folders/${data?._id}`)}
                key={index}
              >
                <div className="flex justify-between items-center pt-[7.83px] px-[11.74px]">
                  <div className="w-[15.69px] lg:w-[32px]">
                    <CustomImage src={FolderIcon} alt="folder" />
                  </div>
                  <div className=" flex gap-[8px]">
                    {/* <AiOutlineHeart className="w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]" /> */}
                    <AiOutlineMore className="w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]" />
                  </div>
                </div>
                <div className=" pt-[7.83px] px-[11.74px] lg:py-[16px] lg:px-[24px]">
                  <h1 className="text-[7.83px] lg:text-[16px] font-semibold capitalize">
                    {data.firstName} {data.lastName}
                  </h1>
                  {/* <p className="text-[3.91px] lg:text-[8px] lg:whitespace-wrap">
                    {data?.address}
                  </p> */}
                </div>
                {/* <div className="px-[11.74px]">
                  <div className="lg:w[376px]">
                    <CustomImage src={Home} alt="home" />
                  </div>
                </div> */}
                <div className="flex justify-end py-[7.83px] px-[11.74px] lg:py-[16px] lg:px-[24px]">
                  {/* <div className="flex items-center lg:w-[160px] gap-[8px]">
                    <p className="text-[5.87px] lg:text-[12px] whitespace-nowrap">
                      {data?.files?.length || 0}{" "}
                      {`${data?.files?.length > 1 ? "files" : "file"}`}
                    </p>
                    <FaDiamond className="w-[5px] h-[5px]" />
                    <p className="text-[5.87px] lg:text-[12px]">
                      {formatBytes(
                        data?.files?.reduce(
                          (prev: any, curr: any) => prev + curr.size,
                          0
                        )
                      )}
                    </p>
                  </div> */}
                  {/* <p className=" lg:w-[160px] text-[5.87px] lg:text-[12px] whitespace-nowrap">
                    {data?.files?.length}{" "}
                    {`${data?.files?.length > 1 ? "files" : "file"}`}
                  </p> */}
                  <p className="whitespace-nowrap text-[5.87px] lg:text-[12px]">
                    Created {moment(data?.createdAt).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {createFolder && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setCreateFolder(false)}
        >
          <CreateFolderModal
            closeModal={() => setCreateFolder(false)}
            loadOptionsOne={builders.map((data: any) => {
              return {
                label: `${data?.firstName} ${data?.lastName}`,
                value: data._id,
              };
            })}
            loadOptionsTwo={owners.map((data: any) => {
              return {
                label: `${data?.firstName} ${data?.lastName}`,
                value: data._id,
              };
            })}
            getData={getFolders}
          />
        </Modal>
      )}
      {createUser && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setCreateUser(false)}
        >
          <UserModal
            closeModal={() => setCreateUser(false)}
            // getAllData={getUsers}
            builderoptions={builders.map((data: any) => {
              return {
                label: `${data?.firstName} ${data?.lastName}`,
                value: data._id,
              };
            })}
          />
        </Modal>
      )}
      {uploadImage && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setUploadImage(false)}
        >
          <UploadImageModal
            closeModal={() => setUploadImage(false)}
            data={folders}
            getData={getFolders}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdminDashboard;
