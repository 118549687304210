import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/shared/layouts/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import { TiFolder } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";
import FileCard from "../../components/shared/cards/FileCard";
import useFolders from "../../hooks/useFolders";
import moment from "moment";
import { formatBytes } from "../../utils";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/shared/modalWrapper/Modal";
import ViewMedia from "../../components/modal/ViewMedia";
import { useSelector } from "react-redux";
import useUsers from "../../hooks/useUsers";
import useUploads from "../../hooks/useUploads";
const ProjectDetail = () => {
  const { id } = useParams();
  const wrapperRef = useRef(null);
  const [viewFile, setViewFile] = useOutsideClick(wrapperRef);
  const { islogin, user } = useSelector((state: any) => state.auth);
  const [file, setFile] = useState<any>();
  const [scale, setScale] = useState<number>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [imageViewIndex, setImageViewIndex] = useState<any>();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { folder, getFolderById } = useFolders({ id: id });
  const { addfavouriteFile } = useUsers({});
  const { downloadMedia } = useUploads();

  let newFav: any = {};

  let fav = user?.favouriteFiles?.forEach((data: any) => {
    newFav[data.file_id] = data._id;
  });

  const downDoc = async (url: any, name: string) => {
    const response = await downloadMedia(url);
    const link = document.createElement("a");
    link.href = response?.data;
    link.setAttribute("download", `${name}`);
    document.body.appendChild(link);
    link.click();
  };

  const handleZoomIn = () => {
    setScale((scale: any) => (scale <= 1.0 && scale === 1 ? scale + 0.1 : 1));
  };

  const handleZoomOut = () => {
    setScale((scale: any) =>
      scale > 0.1 && scale !== 0.1 ? scale - 0.1 : 0.1
    );
  };
  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[12px] w-full py-[24px] px-[16px] lg:px-[64px] lg:py-[48px]">
        <div>
          <div className="flex items-center gap-[8px]">
            <TiFolder className="w-[28px] h-[28px]" />
            {user?.role === "BUILDER" ? (
              <>
                <MdOutlineChevronRight className="w-[16px] h-[16px]" />
                <h1 className="text-[14px] font-semibold">
                  {folder?.data?.owner
                    ? `${folder?.data?.owner?.firstName} ${folder?.data?.owner?.lastName}`
                    : ""}
                </h1>
              </>
            ) : (
              ""
            )}
            <MdOutlineChevronRight className="w-[16px] h-[16px]" />
            <h1 className="text-[14px] font-semibold">
              {folder?.data?.folderName}
            </h1>
          </div>
        </div>
        <h1 className="text-[30px] font-semibold">
          {folder?.data?.folderName}
        </h1>
        <div className="flex flex-col gap-y-[12px]">
          <p className="underline text-[#2E90FA]">
            {folder?.data?.owner?.address}
          </p>
          <p>
            Created {moment(folder?.data?.createdAt).format("MMMM DD, YYYY")}
          </p>
          <p>
            {formatBytes(
              folder?.data?.files?.reduce(
                (prev: any, curr: any) => prev + curr.size,
                0
              )
            )}
          </p>
        </div>
        <div className="border-t border-gray-300">
          <div className="pt-[24px]"></div>
        </div>
      </div>
      {/* <div className="flex flex-col items-center px-[64px] lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full"> */}
      <div className="w-full flex flex-wrap px-[16px] lg:px-[64px] gap-[8px] lg:gap-[16px] pb-[72px]">
        {folder?.data?.files.map((data: any, index: number) => {
          let newFav: any = {};
          let fav = user?.favouriteFiles.forEach((data: any) => {
            newFav[data.file_id] = data._id;
          });
          return (
            <FileCard
              key={index}
              data={data}
              handleClick={() => {
                setViewFile(true);
                setImageViewIndex(index);
                setFile(data);
              }}
              favourite={newFav[data._id] === data._id ? true : false}
              handleUnFavourite={() => {
                addfavouriteFile({
                  ...data,
                  file_id: data._id,
                  type: "remove",
                });
                getFolderById();
              }}
              handleFavourite={() => {
                addfavouriteFile({ ...data, file_id: data._id, type: "add" });
                getFolderById();
              }}
            />
          );
        })}
      </div>
      {viewFile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => {
            setViewFile(false);
            setShowInfo(false);
          }}
        >
          <ViewMedia
            closeModal={() => {
              setViewFile(false);
              setShowInfo(false);
            }}
            data={folder?.data?.files}
            scale={scale}
            favourite={
              folder?.data?.files[imageViewIndex]?._id ===
              newFav[folder?.data?.files[imageViewIndex]?._id]
                ? true
                : false
            }
            imageIndex={imageViewIndex ? imageViewIndex : 0}
            showInfo={showInfo}
            handleShowInfo={() => {
              setShowInfo((prev) => !prev);
            }}
            prev={() => {
              setImageViewIndex((index: any) => {
                if (index === 0) return folder?.data?.files.length - 1;
                return index - 1;
              });
              setFile(folder?.data?.files[imageViewIndex]);
            }}
            handleDownload={() => {
              downDoc(
                folder?.data?.files[imageViewIndex]?.key,
                folder?.data?.files[imageViewIndex]?.key
              );
            }}
            next={() => {
              setImageViewIndex((index: any) => {
                if (index === folder?.data?.files.length - 1) return 0;
                return index + 1;
              });
              setFile(folder?.data?.files[imageViewIndex]);
            }}
            handleZoomIn={() => {
              handleZoomIn();
            }}
            handleZoomOut={() => {
              handleZoomOut();
            }}
            handleUnFavourite={() => {
              addfavouriteFile({
                ...folder?.data?.files[imageViewIndex],
                file_id: folder?.data?.files[imageViewIndex]._id,
                type: "remove",
              });
              getFolderById();
            }}
            handleFavourite={() => {
              addfavouriteFile({
                ...folder?.data?.files[imageViewIndex],
                file_id: folder?.data?.files[imageViewIndex]._id,
                type: "add",
              });
              getFolderById();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectDetail;

// <div className="flex flex-col items-center w-[370px] lg:w-[316px] h-[243px] rounded-[12px] border border-gray-300">
//   <div className="p-[16px] flex">
//     <p className="w-[290px] lg:w-[236px]">Painting work.jpg</p>
//     <div className="flex gap-[8px]">
//       <AiOutlineHeart className="w-[20px] h-[20px]" />
//       <AiOutlineMore className="w-[20px] h-[20px]" />
//     </div>
//   </div>
//   <div>
//     <div>
//       <CustomImage src={Home} alt="home" />
//     </div>
//   </div>
//   <div className="p-[16px] flex justify-between">
//     <p className="w-[214px] lg:w-[160px] text-[12px]">50 MB</p>
//     <p className="whitespace-nowrap text-[12px]">
//       Uploaded Oct 1, 2024
//     </p>
//   </div>
// </div>
