import React, { Suspense, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import { getAuthRouter } from "./router/auth";
import { getDashboardRouter } from "./router/dashboard";
import { Toaster } from "react-hot-toast";
import { FiltersWrapper } from "./components/FiltersWrapper";

function App() {
  const [router, setRouter] = useState<any>([]);
  const [hasRouter, setHasRouter] = useState(false);
  useEffect(() => {
    const createRoute = async () => {
      try {
        const routerArray: any[] = [];
        const dashboardRouters: { path: string; element: JSX.Element }[] =
          getDashboardRouter();
        const authRouters: { path: string; element: JSX.Element }[] =
          getAuthRouter();
        const defaultRouter: RouteObject[] = routerArray.concat(
          authRouters,
          dashboardRouters
        );
        setRouter(createBrowserRouter(defaultRouter));
        setHasRouter(true);
      } catch (e) {
        const routerArray: any[] = [];
        const dashboardRouters: any[] = getDashboardRouter();
        const authRouters: { path: string; element: JSX.Element }[] =
          getAuthRouter();
        const defaultRouter = routerArray.concat(authRouters, dashboardRouters);
        setRouter(createBrowserRouter(defaultRouter));
        setHasRouter(true);
      }
    };
    createRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div>
        {hasRouter && (
          <Suspense fallback={<div>Loading...</div>}>
            <FiltersWrapper>
              <Toaster />
              <RouterProvider router={router} />
            </FiltersWrapper>
          </Suspense>
        )}
      </div>
    </>
  );
}

export default App;
