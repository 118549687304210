import React, { useState } from "react";
import AdminAuthLayout from "../../../components/shared/layouts/AdminAuthLayout";
import { Form, Formik } from "formik";
import InputText from "../../../components/shared/input/InputText";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { userResetPasswordSchema } from "../../../utils/validationSchema/auth";

const AdminResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const { userPasswordReset } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const signIn = async (data: any) => {
    try {
      setLoading(true);
      const res = await userPasswordReset({ ...data, token });
      if (res.code === "00") {
        toast.success(res?.message);
        navigate("/admin/login");
      }
      if (res.code !== "00") {
        setLoading(false);
        toast.error(res.message || "Error");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <AdminAuthLayout>
      <div className="flex-grow flex flex-col  lg:flex-col lg:flex-grow lg:items-center lg:justify-center lg: lg:w-full gap-[32px] lg:gap-[88px] px-[16px] lg:px-[64px]">
        <div className="flex flex-col justify-center gap-[24px] lg:w-[411px]">
          <div>
            <h1 className="font-bold text-[24px]">Forgot password</h1>
            <p className="text-[14px] font-normal">
              Provide the detail(s) below to regain access.
            </p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={userResetPasswordSchema}
              onSubmit={signIn}
            >
              {({ handleChange, errors, values, handleSubmit }) => {
                console.log({ errors });
                return (
                  <Form>
                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your password"}
                        label={"Password"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.password}
                        name={"password"}
                        unit={""}
                        type={`${viewPassword ? "text" : "password"}`}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.password}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        inputClassName={""}
                        rightIcon
                        viewPassword={viewPassword}
                        // className={""} //   viewPassword={false}
                        setViewPassword={setViewPassword}
                      />
                    </div>

                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter confirm password"}
                        label={"Confirm password"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.confirmPassword}
                        name={"confirmPassword"}
                        unit={""}
                        type={`${viewPassword ? "text" : "password"}`}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.confirmPassword}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        inputClassName={""} //   viewPassword={false}
                        rightIcon
                        viewPassword={viewPassword}
                        // className={""} //   viewPassword={false}
                        setViewPassword={setViewPassword}
                      />
                    </div>
                    <div className="mt-[24px] w-full">
                      <button
                        className="bg-[#A74225] rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                        type="submit"
                        // onClick={() => handleSubmit()}
                      >
                        {loading ? "loading..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </AdminAuthLayout>
  );
};

export default AdminResetPassword;
