import React, { useEffect, useState } from "react";

let search = "";
const useSearch = ({
  searchText,
  setQueryString,
}: {
  searchText?: string;
  setQueryString?(): void;
}) => {
  const [query, _setQuery] = useState<string>();
  //   console.log({ query }, "hook");

  // On first run, set initial count
  useEffect(() => {
    if (searchText !== undefined) {
      search = searchText;
    }
  }, [searchText]);

  // If shared count is changed by other hook instances, update internal count
  useEffect(() => {
    _setQuery(search);
  }, []);

  const setQuery = (value: string) => {
    search = value; // Update shared count for use by other hook instances
    // console.log({ value }, "hook");
    _setQuery(value); // Update internal count
  };
  //   console.log({ search }, "hook");

  return { query, setQuery };
};

export default useSearch;
