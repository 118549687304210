import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProjectCard from "../../components/shared/cards/ProjectCard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFolders from "../../hooks/useFolders";
import FolderCard from "../../components/shared/cards/FolderCard";
import { TiFolder } from "react-icons/ti";
import { MdOutlineChevronRight } from "react-icons/md";
import useProjects from "../../hooks/useProject";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import CustomImage from "../../components/shared/customImage";
import Home from "../../assets/images/png/home.png";
import FolderIcon from "../../assets/images/png/foldercloud-Bulk.png";
import moment from "moment";
import { useSelector } from "react-redux";
import useUsers from "../../hooks/useUsers";
import { formatBytes } from "../../utils";
import FileCard from "../../components/shared/cards/FileCard";
import Modal from "../../components/shared/modalWrapper/Modal";
import ViewMedia from "../../components/modal/ViewMedia";
import useOutsideClick from "../../hooks/useOutsideClick";

const Favourite = () => {
  const { islogin, user } = useSelector((state: any) => state.auth);
  const wrapperRef = useRef(null);
  const [viewFile, setViewFile] = useOutsideClick(wrapperRef);
  const [file, setFile] = useState();
  const [userData, setUserData] = useState<any>();
  const { getUserProfileDetail, addfavouriteFolder } = useUsers({});
  const navigate = useNavigate();

  const favFiles: any[] = useMemo(() => {
    return userData?.data?.favouriteFiles.map((data: any) => {
      return {
        ...data,
        type: "file",
      };
    });
  }, [userData?.data?.favouriteFiles]);
  const favFolders = useMemo(() => {
    return userData?.data?.favoriteFolders.map((data: any) => {
      return {
        ...data,
        type: "folder",
      };
    });
  }, [userData?.data?.favoriteFolders]);
  const favArray: any[] = useMemo(() => {
    return favFiles?.concat(favFolders);
  }, [favFiles, favFolders]);

  const getUserDetail = useCallback(async () => {
    const data = await getUserProfileDetail();
    console.log({ data });
    setUserData(data);
  }, []);
  useEffect(() => {
    getUserDetail();
  }, [getUserDetail]);

  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[12px] w-full py-[24px] px-[16px] lg:px-[64px] lg:py-[48px]">
        <div>
          <div className="flex items-center gap-[8px]">
            <TiFolder className="w-[28px] h-[28px]" />
            {/* {user?.role === "BUILDER" ? (
              <>
                <MdOutlineChevronRight className="w-[16px] h-[16px]" />
                <h1 className="text-[14px] font-semibold">{data?.name}</h1>
              </>
            ) : (
              ""
            )} */}
            <MdOutlineChevronRight className="w-[16px] h-[16px]" />
            <h1 className="text-[14px] font-semibold">{"Favourite"}</h1>
          </div>
        </div>
        <h1 className="text-[30px] font-semibold">{"Favourite"}</h1>
        <div className="flex flex-col gap-y-[12px]">
          <p className="underline text-[#2E90FA]">
            {/* {folder?.data?.owner?.address} */}
          </p>
          <p>
            {/* Created {moment(folder?.data?.createdAt).format("MMMM DD, YYYY")} */}
          </p>
          <p>
            {/* {formatBytes(
              folder?.data?.files?.reduce(
                (prev: any, curr: any) => prev + curr.size,
                0
              )
            )} */}
          </p>
        </div>
        <div className="border-t border-gray-300">
          <div className="pt-[24px]"></div>
        </div>
      </div>
      {/* <div className="flex flex-col items-center px-[64px] lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full"> */}
      <div className="flex flex-row px-[16px] lg:px-[64px] flex-wrap items-center lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full">
        {favArray?.map((data: any, index: number) => {
          return (
            <Fragment key={data._id}>
              {data.type === "folder" ? (
                <FolderCard
                  data={{
                    name: data?.folderName,
                    address: data?.owner?.address,
                    files: data?.files,
                    createdAt: data?.createdAt,
                    id: data?._id,
                  }}
                  favourite={true}
                  key={data?._id}
                  handleClick={() => navigate(`/folders/${data?._id}/detail`)}
                  handleUnFavourite={() => {
                    addfavouriteFolder({ file_id: data._id, type: "remove" });
                    getUserDetail();
                    // getFolders();
                  }}
                  handleFavourite={() => {
                    addfavouriteFolder({ file_id: data._id, type: "add" });
                    getUserDetail();
                    // getFolders();
                  }}
                />
              ) : (
                <FileCard
                  data={data}
                  handleClick={() => {
                    setViewFile(true);
                    setFile(data);
                  }}
                  favourite={true}
                  key={data._id}
                />
              )}
            </Fragment>
          );
        })}
      </div>
      {viewFile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewFile(false)}
        >
          <ViewMedia
            closeModal={() => setViewFile(false)}
            data={file}
            // getAllData={getUsers}
          />
        </Modal>
      )}
    </div>
  );
};

export default Favourite;
