import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/shared/layouts/DashboardLayout";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import Home from "../../../assets/images/png/home.png";
import CustomImage from "../../components/shared/customImage";
import FolderCard from "../../components/shared/cards/FolderCard";
import useFolders from "../../hooks/useFolders";
import { useDispatch, useSelector } from "react-redux";
import { MdFilterList } from "react-icons/md";
import DateInput from "../../components/shared/DateInput";
import moment from "moment";
import ProjectCard from "../../components/shared/cards/ProjectCard";
import useUsers from "../../hooks/useUsers";
import { DateFilterFunc, formatBytes } from "../../utils";
import { RiBuildingLine } from "react-icons/ri";
import Modal from "../../components/shared/modalWrapper/Modal";
import TermsOfServiceModal from "../../components/modal/TermsOfServiceModal";
import { setUserData } from "../../feature/auth";
import useSearch from "../../hooks/useSearch";
import useFilters from "../../hooks/useFilters";

const Dashboard = () => {
  const wrapperRef = useRef(null);
  const [page, setPage] = useState<number>(1);
  const [role, setRole] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const { user } = useSelector((state: any) => state.auth);
  const [showFilter, setShowFilter] = useState(false);
  const [viewTos, setViewTos] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formatStartDate, setFormatStartDate] = useState<string | null>();
  const [formatEndDate, setFormatEndDate] = useState<string | null>();
  const navigate = useNavigate();
  const { updateUserDetail, getUserProfileDetail } = useUsers({});
  const dispatch = useDispatch();
  const ownerId = user?.role === "OWNER" ? user?._id : "";
  const builderID = user?.role === "BUILDER" ? user?._id : "";
  const { folders, loading, totalMedia, getFolders } = useFolders({
    owner: ownerId,
    builder: builderID,
    page,
    limit,
    startDate: formatStartDate,
    endDate: formatEndDate,
    sortBy,
  });
  const { search } = useFilters();

  const { addfavouriteFolder } = useUsers({});
  const userCreated = moment(user?.createdAt).format("MMMM DD YYYY");

  useEffect(() => {
    setViewTos(!user?.acceptedTos);
  }, [user?.acceptedTos]);

  useEffect(() => {
    const getProfile = async () => {
      const userProfiledata: any = await getUserProfileDetail();
      dispatch(setUserData(userProfiledata?.data));
    };
    getProfile();
  }, []);

  const onChange = (dates: any) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    start
      ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
      : setFormatStartDate(null);

    end
      ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
      : setFormatEndDate(null);
  };

  let computedList = user?.assignedOwners;
  if (search) {
    computedList = computedList
      ?.map((data: any) => {
        console.log({ search }, "computed");
        console.log({ data });
        return {
          ...data,
          fullName: `${data?.firstName} ${data?.lastName}`,
        };
      })

      ?.filter((item: any) =>
        Object.values(item).some(
          (val) => typeof val === "string" && val.includes(search)
        )
      );
  }

  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[24px] w-full py-[24px] px-[16px] lg:px-[64px] lg:py-[48px]">
        <div>
          <h1 className="text-[30px] font-semibold">
            Welcome {user?.firstName}
          </h1>
          <p className="text-[16px]">Check out your latest project updates.</p>
        </div>
        {user?.role === "OWNER" ? (
          <>
            <div className="flex flex-col gap-y-[12px]">
              <p className="underline text-[#2E90FA]">{user?.address}</p>
              <p>{userCreated}</p>
              <p>
                {formatBytes(totalMedia?.length > 0 ? totalMedia[0]?.count : 0)}
              </p>
            </div>
            <div className="border-t border-gray-300">
              <div className="pt-[24px]"></div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="flex flex-col gap-[16px] lg:gap-0 lg:flex-row lg:justify-between ">
          {user?.role === "BUILDER" ? (
            <div className="flex h-[40px]">
              <div className="w-[173px]  lg:w-[189px]">
                <DateInput
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChange}
                />
              </div>
              <div className="flex rounded-md w-max">
                {[
                  { name: "30 days", year: "THIRTY_DAYS" },
                  { name: "7 days", year: "SEVEN_DAYS" },
                  { name: "Today", year: "TODAY" },
                ]?.map((data, index) => {
                  return (
                    <button
                      key={index}
                      className={`w-max border border-gray-300 py-[10px] px-[8px] text-sm ${
                        index === 2 ? "rounded-br rounded-tr" : ""
                      } ${
                        index === 0
                          ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                          : ""
                      }`}
                      onClick={() => {
                        setFormatStartDate(DateFilterFunc(data?.year));
                        setFormatEndDate(moment().format("YYYY-MM-DD"));
                      }}
                    >
                      {data.name}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="relative">
            <button
              className="flex gap-[8px] h-[40px] items-center border border-gray-300 py-[10px] px-[16px] capitalize"
              onClick={() => setShowFilter((prev) => !prev)}
            >
              <MdFilterList />
              sort
            </button>
            {user?.role === "BUILDER" && showFilter && (
              <div
                className="p-[14px] flex flex-col gap-[8px] border border-gray-300 absolute left-0 lg:left-[-208px] top-[42px] lg:right-[0px] w-[297px] height-max bg-white rounded-[8px] z-50"
                onMouseLeave={() => setShowFilter(false)}
              >
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setSortBy("DESC")}
                >
                  <h2 className="text-[14px] font-medium">New - Old</h2>
                  {/* <MdArrowForwardIos className="w-[24px]" /> */}
                </div>
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setSortBy("ASC")}
                >
                  <h2 className="text-[14px] font-medium">Old - New</h2>
                  {/* <MdArrowForwardIos className="w-[24px]" /> */}
                </div>
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setSortBy("DESC")}
                >
                  <h2 className="text-[14px] font-medium">All Folders</h2>
                  {/* <MdArrowForwardIos className="w-[24px]" /> */}
                </div>
              </div>
            )}{" "}
            {showFilter && user?.role === "OWNER" && (
              <div
                className="p-[14px] flex flex-col gap-[8px] border border-gray-300 absolute left-0 top-[42px] lg:right-[0px] w-[297px] height-max bg-white rounded-[8px] z-50"
                onMouseLeave={() => setShowFilter(false)}
              >
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setSortBy("DESC")}
                >
                  <h2 className="text-[14px] font-medium">New - Old</h2>
                  {/* <MdArrowForwardIos className="w-[24px]" /> */}
                </div>
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setSortBy("ASC")}
                >
                  <h2 className="text-[14px] font-medium">Old - New</h2>
                  {/* <MdArrowForwardIos className="w-[24px]" /> */}
                </div>
                <div
                  className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                  onClick={() => setSortBy("DESC")}
                >
                  <h2 className="text-[14px] font-medium">All Folders</h2>
                  {/* <MdArrowForwardIos className="w-[24px]" /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {user?.role === "BUILDER" ? (
        <div className="flex flex-row px-[16px] lg:px-[64px] flex-wrap items-center lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full">
          {/* <div> */}
          <FolderCard
            data={{
              name: "favorites",
              files: user?.favouriteFiles,
            }}
            handleClick={() => navigate(`/users/favourite`)}
            showFileCount={false}
          />
          {/* </div> */}
          {computedList?.map((data: any) => {
            console.log({ data }, "computedlist");
            let newFav: any = {};
            let fav = user?.favoriteFolders.forEach((data: any) => {
              newFav[data._id] = data._id;
            });
            return (
              <FolderCard
                data={{
                  name: `${data.firstName} ${data?.lastName}`,
                  address: data?.address,
                  files: data?.projects?.files,
                  createdAt: data?.createdAt,
                }}
                key={data?._id}
                ownerId={ownerId}
                builderID={builderID}
                type={"ownerCard"}
                showFileCount={false}
                showFavorite={false}
                handleClick={() => navigate(`/folders/${data?._id}`)}
                favourite={newFav[data._id] === data._id ? true : false}
                handleUnFavourite={() => {
                  addfavouriteFolder({ file_id: data._id, type: "remove" });
                  getFolders();
                }}
                handleFavourite={() => {
                  addfavouriteFolder({ file_id: data._id, type: "add" });
                  getFolders();
                }}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex flex-row flex-wrap px-[16px] lg:px-[64px] lg:flex-row lg:flex-wrap gap-[8px] lg:gap-[16px] pb-[72px] w-full">
          {/* <div> */}
          <FolderCard
            data={{
              name: "favorites",
              files: user?.favouriteFiles,
            }}
            showFileCount={false}
            handleClick={() => navigate(`/users/favourite`)}
          />
          {/* </div> */}
          {folders?.map((data: any, folderindex: number) => {
            let newFav: any = {};
            let fav = user?.favoriteFolders.forEach((data: any) => {
              newFav[data._id] = data._id;
            });
            return (
              <FolderCard
                data={{
                  name: data?.folderName,
                  address: data?.owner?.address,
                  files: data?.files,
                  createdAt: data?.createdAt,
                }}
                key={`${data?._id}`}
                ownerId={ownerId}
                builderID={builderID}
                handleClick={() => navigate(`/folders/${data?._id}/detail`)}
                favourite={newFav[data._id] === data._id ? true : false}
                handleUnFavourite={() => {
                  addfavouriteFolder({ file_id: data._id, type: "remove" });
                  getFolders();
                }}
                handleFavourite={() => {
                  addfavouriteFolder({ file_id: data._id, type: "add" });
                  getFolders();
                }}
              />
            );
          })}
        </div>
      )}

      {viewTos && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewTos(false)}
        >
          <TermsOfServiceModal
            closeModal={() => setViewTos(false)}
            userData={user}
          />
        </Modal>
      )}
    </div>
  );
};

export default Dashboard;

{
  /* <div className="flex flex-col items-center w-[370px] lg:w-[316px] h-[243px] rounded-[12px] border border-gray-300">
  <div className="p-[16px] flex">
    <p className="w-[290px] lg:w-[236px]">Painting work.jpg</p>
    <div className="flex gap-[8px]">
      <AiOutlineHeart className="w-[20px] h-[20px]" />
      <AiOutlineMore className="w-[20px] h-[20px]" />
    </div>
  </div>
  <div>
    <div>
      <CustomImage src={Home} alt="home" />
    </div>
  </div>
  <div className="p-[16px] flex justify-between">
    <p className="w-[214px] lg:w-[160px] text-[12px]">50 MB</p>
    <p className="whitespace-nowrap text-[12px]">Uploaded Oct 1, 2024</p>
  </div>
</div>; */
}
