import React, { useRef, useState } from "react";
import FileCard from "../../../components/shared/cards/FileCard";
import useProjects from "../../../hooks/useProject";
import { useParams } from "react-router-dom";
import { TiFolder } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";
import Modal from "../../../components/shared/modalWrapper/Modal";
import ViewMedia from "../../../components/modal/ViewMedia";
import useFolders from "../../../hooks/useFolders";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { formatBytes } from "../../../utils";
import moment from "moment";
import { MdFilterList } from "react-icons/md";
import UploadImageModal from "../../../components/modal/UploadImageModal";
import { GoPlus } from "react-icons/go";
import useUploads from "../../../hooks/useUploads";
import useUsers from "../../../hooks/useUsers";
import { useSelector } from "react-redux";
import DeleteModal from "../../../components/modal/DeleteModal";

const AdminProjectDetail = () => {
  const wrapperRef = useRef(null);
  const { id } = useParams();
  const [viewFile, setViewFile] = useOutsideClick(wrapperRef);
  const [uploadImage, setUploadImage] = useOutsideClick(wrapperRef);
  const [deleteUser, setDeleteUser] = useOutsideClick(wrapperRef);
  const { islogin, user } = useSelector((state: any) => state.auth);
  const [file, setFile] = useState<any>();
  const [scale, setScale] = useState<number>(1);
  const [imageViewIndex, setImageViewIndex] = useState<any>();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { folder, getFolderById, deleteFileFromFolder } = useFolders({
    id: id,
  });
  const [showsettings, setShowsettings] = useState(false);
  const [fileIndex, setFileIndex] = useState<number>();
  const { addfavouriteFile } = useUsers({});
  const { downloadMedia } = useUploads();
  console.log({ fileIndex });

  const downDoc = async (url: any, name: string) => {
    const response = await downloadMedia(url);
    const link = document.createElement("a");
    link.href = response?.data;
    link.setAttribute("download", `${name}`);
    document.body.appendChild(link);
    link.click();
  };
  const handleZoomIn = () => {
    setScale((scale: any) => (scale <= 1.0 && scale === 1 ? scale + 0.1 : 1));
  };

  const handleZoomOut = () => {
    setScale((scale: any) =>
      scale > 0.1 && scale !== 0.1 ? scale - 0.1 : 0.1
    );
  };

  let newFav: any = {};

  let fav = user?.favouriteFiles.forEach((data: any) => {
    newFav[data.file_id] = data._id;
  });
  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[12px] w-full py-[24px] px-[16px] lg:px-[24px] lg:py-[24px]">
        <div>
          <div className="flex items-center gap-[8px]">
            <TiFolder className="w-[28px] h-[28px]" />
            <MdOutlineChevronRight className="w-[16px] h-[16px]" />
            <h1 className="text-[14px] font-semibold">
              {folder?.data?.folderName}
            </h1>
          </div>
        </div>
        <h1 className="text-[30px] font-semibold">
          {folder?.data?.folderName}
        </h1>
        <div className="flex flex-col gap-y-[12px]">
          <p className="underline text-[#2E90FA]">
            {folder?.data?.owner?.address}
          </p>
          <p>
            Created {moment(folder?.data?.createdAt).format("MMMM DD, YYYY")}
          </p>
          <p>
            {" "}
            {formatBytes(
              folder?.data?.files?.reduce(
                (prev: any, curr: any) => prev + curr.size,
                0
              )
            )}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-bewteen py-[24px] px-[16px] lg:px-[24px] lg:py-[24px]">
        <div className="w-full">
          <p className="text-[14px] font-semibold">Files</p>
          <p className="text-[12px]">
            {`${folder?.data?.files?.length} ${
              folder?.data?.files?.length <= 1 ? "File" : "Files"
            }`}
          </p>
        </div>
        <div className="flex gap-[12px]">
          <button
            className="flex gap-[8px] h-[40px] items-center border border-gray-300 py-[10px] px-[16px] capitalize"
            // onClick={() => setShowFilter((prev) => !prev)}
          >
            <MdFilterList />
            Filters
          </button>
          <button
            className="flex gap-[8px] whitespace-nowrap h-[40px] items-center border border-gray-300 py-[10px] px-[16px] capitalize"
            onClick={() => setUploadImage(true)}
          >
            <GoPlus />
            Add media
          </button>
        </div>
        <div className="pt-[24px]">
          <div className=" border-t border-gray-300"></div>
        </div>
      </div>
      <div className="flex flex-col items-center px-[24px] lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full">
        {folder?.data?.files.map((data: any, index: number) => {
          console.log({ fileIndex });
          // console.log({ index });
          return (
            <FileCard
              key={index}
              data={data}
              handleClick={() => {
                setViewFile(true);
                setImageViewIndex(index);
                setFile(data);
              }}
              showFavorite={false}
              favourite={newFav[data._id] === data._id ? true : false}
              handleUnFavourite={() => {
                addfavouriteFile({
                  ...data,
                  file_id: data._id,
                  type: "remove",
                });
                getFolderById();
              }}
              handleFavourite={() => {
                addfavouriteFile({ ...data, file_id: data._id, type: "add" });
                getFolderById();
              }}
              currentIndex={index}
              fileIndex={fileIndex}
              handleSettings={() => {
                setFileIndex(index);
                // if (fileIndex === index) {
                setShowsettings((prev) => !prev);
                // }
              }}
              showSettings={showsettings}
              handleFileDelete={() => {
                setDeleteUser(true);
                setFile(data);
                setShowsettings(false);
              }}
            />
          );
        })}
      </div>
      {viewFile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewFile(false)}
        >
          <ViewMedia
            closeModal={() => setViewFile(false)}
            data={folder?.data?.files}
            scale={scale}
            favourite={
              folder?.data?.files[imageViewIndex]?._id ===
              newFav[folder?.data?.files[imageViewIndex]?._id]
                ? true
                : false
            }
            imageIndex={imageViewIndex ? imageViewIndex : 0}
            prev={() => {
              setImageViewIndex((index: any) => {
                if (index === 0) return folder?.data?.files.length - 1;
                return index - 1;
              });
              setFile(folder?.data?.files[imageViewIndex]);
            }}
            handleDownload={() => {
              downDoc(
                folder?.data?.files[imageViewIndex]?.key,
                folder?.data?.files[imageViewIndex]?.key
              );
            }}
            next={() => {
              setImageViewIndex((index: any) => {
                if (index === folder?.data?.files.length - 1) return 0;
                return index + 1;
              });
              setFile(folder?.data?.files[imageViewIndex]);
            }}
            handleZoomIn={() => {
              handleZoomIn();
            }}
            handleZoomOut={() => {
              handleZoomOut();
            }}
            handleUnFavourite={() => {
              addfavouriteFile({
                ...folder?.data?.files[imageViewIndex],
                file_id: folder?.data?.files[imageViewIndex]._id,
                type: "remove",
              });
              getFolderById();
            }}
            handleFavourite={() => {
              addfavouriteFile({
                ...folder?.data?.files[imageViewIndex],
                file_id: folder?.data?.files[imageViewIndex]._id,
                type: "add",
              });
              getFolderById();
            }}
          />
        </Modal>
      )}
      {deleteUser && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setDeleteUser(false)}
        >
          <DeleteModal
            closeModal={() => setDeleteUser(false)}
            handleDelete={() => {
              deleteFileFromFolder(id as string, file?._id);
            }}
          />
        </Modal>
      )}

      {uploadImage && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setUploadImage(false)}
        >
          <UploadImageModal
            closeModal={() => setUploadImage(false)}
            data={[]}
            getData={getFolderById}
            userData={{
              id: id,
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdminProjectDetail;
