import React, { useRef, useState } from "react";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import CustomImage from "../../../components/shared/customImage";
import Home from "../../../assets/images/png/home.png";
import FolderIcon from "../../../assets/images/png/foldercloud-Bulk.png";
import { FaDiamond } from "react-icons/fa6";
import moment from "moment";
import { formatBytes } from "../../../utils";
import { FaHeart } from "react-icons/fa";

const FolderCard = ({
  data,
  favourite,
  handleClick,
  handleFavourite,
  handleUnFavourite,
  handleSettings,
  showSettings,
  handleFolderDelete,
  currentIndex,
  fileIndex,
  showFavorite = true,
  type,
  showFileCount = true,
  ownerId,
  builderID,
}: any) => {
  console.log({ data });
  return (
    <>
      <div className="flex flex-col w-[180.96px] h-[78.25px] lg:w-[313px] lg:h-[160px]  rounded-[12px] border border-gray-300 cursor-pointer">
        <div className="lg:pb-[16px] lg:pt-[20px] lg:px-[24px]">
          <div className="flex justify-between items-center pt-[7.83px] px-[11.74px] lg:px-0 relative">
            <div className="w-[15.69px] lg:w-[32px]">
              <CustomImage src={FolderIcon} alt="folder" />
            </div>
            {data?.name !== "favorites" ? (
              <div className=" flex gap-[8px]">
                {showFavorite ? (
                  <div>
                    {favourite ? (
                      <FaHeart
                        className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
                        style={{ color: "red" }}
                        onClick={() => {
                          if (handleUnFavourite) {
                            handleUnFavourite();
                          }
                        }}
                      />
                    ) : (
                      <AiOutlineHeart
                        className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
                        style={{ backgroundColor: `${favourite ? "red" : ""}` }}
                        onClick={() => {
                          if (handleFavourite) {
                            handleFavourite();
                          }
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <AiOutlineMore
                    className="w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]"
                    onClick={() => {
                      if (handleSettings) {
                        handleSettings();
                      }
                    }}
                  />
                </div>
                {showSettings && fileIndex === currentIndex ? (
                  <div className="bg-white absolute top-[35px] border border-gray-300 w-[80px] right-[9px]">
                    <div
                      className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                      onClick={() => {
                        if (handleFolderDelete) {
                          handleFolderDelete();
                        }
                      }}
                    >
                      <h2 className="text-[14px] font-medium text-center">
                        Delete
                      </h2>
                      {/* <MdArrowForwardIos className="w-[24px]" /> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="flex flex-col gap-[8px]"
            onClick={() => {
              handleClick();
            }}
          >
            <div className=" pt-[7.83px] px-[11.74px] lg:px-0">
              <h1 className="text-[7.83px] lg:text-[16px] font-semibold capitalize">
                {data?.name}
              </h1>
              {/* <p className="text-[3.91px] lg:text-[8px] lg:whitespace-wrap">
                {data?.address}
              </p> */}
            </div>
            {/* <div className="">
              <div className="lg:w-[376px] lg:h-[130px]">
                <CustomImage src={Home} alt="home" />
              </div>
            </div> */}
          </div>
        </div>
        <div
          className={`flex ${
            !showFileCount ? "justify-end" : "justify-between"
          } py-[7.83px] px-[11.74px] lg:pb-[16px] lg:px-[24px]`}
        >
          {showFileCount ? (
            <div className="flex items-center lg:w-[160px] gap-[8px]">
              <p className="text-[5.87px] lg:text-[12px] whitespace-nowrap">
                {data?.files?.length || 0}{" "}
                {`${data?.files?.length > 1 ? "files" : "file"}`}
              </p>
              <FaDiamond className="w-[5px] h-[5px]" />
              <p className="text-[5.87px] lg:text-[12px]">
                {formatBytes(
                  data?.files?.reduce(
                    (prev: any, curr: any) => prev + curr.size,
                    0
                  )
                )}
              </p>
            </div>
          ) : (
            <></>
          )}
          <p className="whitespace-nowrap text-[5.87px] lg:text-[12px]">
            Created {moment(data?.createdAt).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </>
  );
};

export default FolderCard;
