import React from "react";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import CustomImage from "../customImage";
import Home from "../../../assets/images/png/home.png";
import { formatBytes } from "../../../utils";
import moment from "moment";
import { FaHeart } from "react-icons/fa";

const FileCard = ({
  data,
  handleClick,
  handleFavourite,
  handleUnFavourite,
  favourite,
  handleSettings,
  showSettings,
  handleFileDelete,
  handleHover,
  fileIndex,
  currentIndex,
  showFavorite = true,
}: {
  data: any;
  handleClick?: () => void;
  handleFavourite?: () => void;
  handleUnFavourite?: () => void;
  favourite: boolean;
  handleSettings?(): void;
  showSettings?: any;
  handleFileDelete?(): void;
  handleHover?(): void;
  currentIndex?: number;
  fileIndex?: number;
  showFavorite?: boolean;
}) => {
  return (
    <div
      className="flex flex-col items-center w-[175px] h-[152.59px] lg:w-[316px] lg:h-[243px] rounded-[12px] border border-gray-300 cursor-pointer"
      onMouseDown={() => {
        if (handleHover) {
          handleHover();
        }
      }}
    >
      <div className="p-[16px] flex justify-between w-full">
        <p className=" text-[5.87px] font-semibold lg:text-[12px]">
          {data?.name}
        </p>
        <div className="flex gap-[8px] relative">
          {showFavorite ? (
            <div>
              {favourite ? (
                <FaHeart
                  className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
                  style={{ color: "red" }}
                  onClick={() => {
                    if (handleUnFavourite) {
                      handleUnFavourite();
                    }
                  }}
                />
              ) : (
                <AiOutlineHeart
                  className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
                  style={{ backgroundColor: `${favourite ? "red" : ""}` }}
                  onClick={() => {
                    if (handleFavourite) {
                      handleFavourite();
                    }
                  }}
                />
              )}
            </div>
          ) : (
            <></>
          )}
          <div>
            <AiOutlineMore
              className="w-[20px] h-[20px]"
              onClick={() => {
                if (handleSettings) {
                  handleSettings();
                }
              }}
            />
          </div>
          {showSettings && fileIndex === currentIndex ? (
            <div className="bg-white absolute top-[20px] border border-gray-300 w-[80px] right-[9px]">
              <div
                className="flex justify-between items-center py-[2px] px-[6px] cursor-pointer"
                onClick={() => {
                  if (handleFileDelete) {
                    handleFileDelete();
                  }
                }}
              >
                <h2 className="text-[14px] font-medium text-center">Delete</h2>
                {/* <MdArrowForwardIos className="w-[24px]" /> */}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        onClick={() => {
          if (handleClick) {
            handleClick();
          }
        }}
      >
        <div>
          <div className="w-[165.34px] h-[68.78px] lg:w-[284px] lg:h-[141px]">
            <CustomImage src={data?.mediaUrl} alt="home" />
          </div>
        </div>
        <div className="p-[16px] flex justify-between w-full">
          <p className="text-[5.87px] lg:text-[12px]">
            {formatBytes(data.size)}
          </p>
          <p className="whitespace-nowrap text-[5.87px] lg:text-[12px]">
            Uploaded {moment(data?.updatedAt).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FileCard;
