import React, { useState } from "react";
import { Form, Formik } from "formik";

import Header from "../../components/shared/header/AuthHeader";
import Footer from "../../components/shared/footer";
import CustomImage from "../../components/shared/customImage";
import BuilderImage from "../../assets/images/png/builder-image.png";
import Logo from "../../assets/images/png/mobile-logo.png";
import InputText from "../../components/shared/input/InputText";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthLayout from "../../components/shared/layouts/AuthLayout";
import { userResetPasswordSchema } from "../../utils/validationSchema/auth";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { userPasswordReset } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const signIn = async (data: any) => {
    try {
      setLoading(true);
      const res = await userPasswordReset({ ...data, token });
      if (res.code === "00") {
        toast.success(res?.message);
        navigate("/");
      }
      if (res.code !== "00") {
        setLoading(false);
        toast.error(res.message || "Error");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <AuthLayout>
      <div className="grow flex flex-col lg:flex-row lg:w-full gap-[32px] lg:gap-[88px] px-[16px] lg:px-[64px]">
        <div className="flex flex-col justify-center gap-[24px] lg:w-[411px]">
          <div className="w-[188px]">
            <CustomImage src={Logo} alt="builder" />
          </div>
          <div>
            <h1 className="font-bold text-[24px]">Reset password</h1>
            <p>provide a new password for your account.</p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={userResetPasswordSchema}
              onSubmit={signIn}
            >
              {({ handleChange, errors, values, handleSubmit }) => {
                return (
                  <Form>
                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your password"}
                        label={"Password"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.password}
                        name={"password"}
                        unit={""}
                        type={"password"}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.password}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        viewPassword={false}
                        inputClassName={""}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>

                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter confirm password"}
                        label={"Confirm password"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.confirmPassword}
                        name={"confirmPassword"}
                        unit={""}
                        type={"password"}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.confirmPassword}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        inputClassName={""} //   viewPassword={false}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>
                    <div className="mt-[24px] w-full">
                      <button
                        className="bg-[#A74225] rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                        type="submit"
                        // onClick={() => handleSubmit()}
                      >
                        {loading ? "loading..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div>
          <div className="lg:h-[566.38px]">
            <CustomImage src={BuilderImage} alt="builder" />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
