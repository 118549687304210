"use client";
import React, {
  Children,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { BsBoxArrowRight } from "react-icons/bs";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiArrowFatLinesRight, PiArrowFatLinesLeft } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../feature/auth";
import useOutsideClick from "../../../hooks/useOutsideClick";
// import SettingsModal from "../modal/SettingsModal";
// import Modal from "../Modal";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
// import Link from "next/link";
// import { useRouter, usePathname } from "next/navigation";
// import Image from "next/image";
import { PiUserSquareLight, PiUsers } from "react-icons/pi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { BsFileBarGraph } from "react-icons/bs";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { BsArchive } from "react-icons/bs";
import { GoTag } from "react-icons/go";
import CustomImage from "../customImage";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/png/logo-small.png";
import Modal from "../modalWrapper/Modal";
import ProfileDetailModal from "../../modal/ProfileDetailModal";
import PasswordModal from "../../modal/PasswordModal";
// import useNav from "@/hooks/useNav";

// const navs = [
//   {
//     id: 1,
//     route: "/dashboard/admin",
//     name: "Dashboard",
//     Icon: BsFileBarGraph,
//     showArrow: false,
//   },
//   {
//     id: 2,
//     route: "/products",
//     name: "Products",
//     Icon: PiUsers,
//     showArrow: false,
//   },
//   {
//     id: 3,
//     route: "/orders",
//     name: "Orders",
//     Icon: BsArchive,
//     showArrow: false,
//   },
//   {
//     id: 3,
//     route: "/transactions",
//     name: "Transactions",
//     Icon: GoTag,
//     showArrow: false,
//   },
//   {
//     id: 6,
//     route: "/admins",
//     name: "Admins",
//     Icon: PiUserSquareLight,
//     showArrow: false,
//   },
// ];
const Sidebar = ({
  setCloseNav,
  closeNav,
  navs = [],
  setShowMobileNav,
}: {
  closeNav: boolean;
  navs: any[];
  setShowMobileNav: Dispatch<SetStateAction<boolean>>;
  setCloseNav: Dispatch<SetStateAction<boolean>>;
}) => {
  let location: any;
  const wrapperRef = useRef(null);
  const [showSettings, setShowSettings] = useOutsideClick(wrapperRef);
  const [changePassword, setChangePassword] = useOutsideClick(wrapperRef);
  const [changePersonalDetail, setChangePersonalDetail] =
    useOutsideClick(wrapperRef);
  const [isSubMenuShow, setIsSubMenuShow] = useState(false);
  const { user } = useSelector((state: any) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = () => {
    localStorage.clear();
    localStorage.removeItem("persist:root");
    dispatch(logout());
    navigate("/admin/login");
  };

  const handlePasswordModal = () => {
    setChangePersonalDetail(false);
    setChangePassword(true);
  };

  return (
    <div className="h-full flex flex-col">
      <div
        className={`flex pt-[32px] ${
          closeNav ? "justify-end items-center" : "justify-between items-center"
        } mb-10 px-2`}
      >
        <FaTimes
          onClick={() => setShowMobileNav(false)}
          className="w-[30px] h-30 h-max lg:hidden mt-3"
        />
        <div className="flex items-centers gap-[56px]">
          <div
            onClick={() => navigate("/admin/dashboard")}
            className="cursor-pointer h-[44px] w-max pr-[20px] pl-[24px] p-0"
          >
            <CustomImage src={Logo} alt="Logo" />
          </div>
        </div>
      </div>
      <div className={`grow flex flex-col ${closeNav ? "items-center" : ""}`}>
        <div className="">
          <ul className="px-[16px]">
            {navs.map(
              ({ id, Icon, name, route, children }: any, index: any) => {
                return (
                  <Link to={`${route}`} key={`${index}`}>
                    <li className={`flex flex-col`}>
                      <div
                        className={`flex items-center justify-between gap-4 h-10 rounded-md ${
                          location === route ? "bg-red-500 text-white" : ""
                        } ${
                          children && isSubMenuShow && location !== route
                            ? "bg-[#F0F1F1]"
                            : ""
                        }  p-2`}
                        onClick={() =>
                          setIsSubMenuShow(children ? !isSubMenuShow : false)
                        }
                      >
                        <div className="flex items-center gap-4">
                          <Icon
                            style={{
                              width: 24,
                              height: 24,
                              // color: `${location === route ? "white" : ""}`,
                            }}
                          />
                          {closeNav ? "" : name}
                        </div>
                        {children ? (
                          <div>
                            {isSubMenuShow ? (
                              <IoIosArrowDown className="self-end" />
                            ) : (
                              <IoIosArrowUp className="self-end" />
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {children && isSubMenuShow && (
                        <div
                          className={`h-max w-full p-2 bg-[#F0F1F1] ${
                            closeNav ? "items-center" : ""
                          }`}
                        >
                          <ul className="h-max w-full flex flex-col boder border-gray-300">
                            {children?.map(
                              (
                                { id, route: routeValue, Icon, name }: any,
                                index: any
                              ) => {
                                return (
                                  <li
                                    key={`${index}-${id}`}
                                    className={`flex items-center  gap-4 h-10 rounded-md mb-1 ${
                                      location === routeValue
                                        ? "bg-green-700 text-white"
                                        : "bg-white"
                                    }   p-2`}
                                    onClick={() => {
                                      // navigate(routeValue)
                                    }}
                                  >
                                    <Icon
                                      style={{
                                        width: 24,
                                        height: 24,
                                        color: `${
                                          location === routeValue
                                            ? "white"
                                            : "black"
                                        }`,
                                      }}
                                    />
                                    {closeNav ? "" : name}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    </li>
                  </Link>
                );
              }
            )}
          </ul>
        </div>
      </div>
      <div className={`flex flex-col ${closeNav ? "items-center" : ""}`}>
        <div className="flex gap-[20px]  px-[16px] pb-[32px]">
          <div
            className="flex gap-[12px] w-[208px] cursor-pointer"
            onClick={() => setChangePersonalDetail(true)}
          >
            <div className="w-[40px] h-[40px] rounded-full">
              <CustomImage src={user?.profileImage} alt="avatar" />
            </div>
            <div>
              <h1 className="text-[12px] font-semibold">{user?.fullName}</h1>
              <p className="font-normal text-[12px]">{user?.email}</p>
            </div>
          </div>
          <BsBoxArrowRight
            className="w-[20px] h-[20px]"
            style={{ cursor: "pointer" }}
            onClick={() => {
              logoutUser();
            }}
          />
        </div>
      </div>
      {changePersonalDetail && (
        <Modal
          closeModal={() => setChangePersonalDetail(false)}
          refProp={wrapperRef}
          variant={"default"}
        >
          <ProfileDetailModal
            closeModal={() => setChangePersonalDetail(false)}
            userData={user}
            handlePasswordModal={() => handlePasswordModal()}
          />
        </Modal>
      )}
      {changePassword && (
        <Modal
          closeModal={() => setChangePassword(false)}
          refProp={wrapperRef}
          variant={"default"}
        >
          <PasswordModal closeModal={() => setChangePassword(false)} />
        </Modal>
      )}
    </div>
  );
};

export default Sidebar;
