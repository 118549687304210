import axiosInstance from "../api/axios";

export const getAllFolders = async ({
  page,
  limit,
  owner,
  builder,
  startDate,
  endDate,
  sortBy,
}: any) => {
  try {
    const params: any = {};
    if (owner) {
      params.owner = owner;
    }
    if (builder) {
      params.builder = builder;
    }
    if (page) {
      params.page = page;
    }
    if (sortBy) {
      params.sort = sortBy;
    }
    if (limit) {
      params.limit = limit;
    }
    if (startDate && endDate) {
      params.startDate = startDate;
      params.endDate = endDate;
    }
    const res = await axiosInstance.get(`/folders`, {
      params,
    });
    // console.log({ res });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createFolder = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/folders`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const addFolderToFavourite = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/folders/folder`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getOneFolder = async ({ id }: { id: string | undefined }) => {
  try {
    const res = await axiosInstance.get(`/folders/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateFolderFiles = async ({
  id,
  data,
}: {
  id: string | undefined;
  data: any;
}) => {
  try {
    const res = await axiosInstance.put(`/folders/${id}/upload`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFolderFile = async (id: string, fileId: string) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.delete(`/folders/${id}/file/${fileId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFolder = async (id: string) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.delete(`/folders/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
