import * as yup from "yup";
export const signInSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup.string().required("Please enter your password"),
});
export const userForgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});
export const userResetPasswordSchema = yup.object({
  password: yup.string().required("Full name is required").min(8, "Too Short!"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .min(8, "Too Short!"),
});
export const createAdminSchema = yup.object({
  role: yup.string().required("Email is required"),
  phoneNumber: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name"),
});
export const editAdminSchema = yup.object({
  role: yup.string().required("Email is required"),
  phoneNumber: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name"),
});
export const createUserSchema = yup.object({
  files: yup.array().required("Please upload an image"),
  role: yup.string().required("Email is required"),
  phoneNumber: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name"),
  builder: yup.string(),
});
export const editUserSchema = yup.object({
  files: yup.array(),
  role: yup.string().required("Email is required"),
  phoneNumber: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name"),
});
export const signUpSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  fullName: yup.string().required("Name is required"),
  phoneNumber: yup.string().required("Name is required").max(11),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Too Short!"),
  confirmPassword: yup
    .string()
    .required("Please enter your password")
    .min(8, "Too Short!"),
});

export const changePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .min(8, "Too Short!")
    .required("oldPassword is required"),
  newPassword: yup
    .string()
    .required("newPassword is required")
    .min(8, "Too Short!"),
  repeatPassword: yup
    .string()
    .min(8, "Too Short!")
    .oneOf([yup.ref("newPassword")], "password must match")
    .required("Required"),
});
export const contactUsSchema = yup.object({
  phoneNumber: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: yup.string().required("Please enter first name"),
  lastName: yup.string().required("Please enter last name"),
  message: yup.string().required("Please enter Message"),
});
