import React from "react";
import { FiltersContextInterface } from "../types/filters";

const defaultFilterContext: FiltersContextInterface = {
  search: "",
  showingFilters: false,
  debouncedSearch: "",
  setSearch: () => {},
  toggleShowingFilters: () => {},
};

export const FiltersContext =
  React.createContext<FiltersContextInterface>(defaultFilterContext);
